@use '@chicksgroup/styles/variables' as v-global;
@use '@chicksgroup/styles/mixins' as m-global;
@import 'styles/mixins';
@import 'styles/variables';

.unknown-route {
    background-color: var(--cg-grey-100);
}

#unknown-route {
    --navbar-height: 65px;
    
    overflow: hidden;
    padding-top: 0;
    margin-block: calc(80px + var(--navbar-height)) 80px;

    @media (width <= calc($tablet + 1px)) {
        margin-block: calc(60px + var(--navbar-height)) 60px;
    }

    @media (width <= $phone) {
        margin-block: calc(40px + var(--navbar-height)) 40px;
    }

    .unknown-container {
        max-width: 1136px;
        margin: 80px auto;
        padding-inline: v-global.$hugePad;
        box-sizing: content-box;

        @media (width <= calc(v-global.$tablet + 1px)) {
            margin: 40px auto;
            padding-inline: v-global.$hugePad;
        }

        @media (width <= $phone) {
            padding-inline: v-global.$bigPad;
        }


        .unknown-container--button {
            font-size: v-global.$medium;
            box-shadow: none;
            height: 52px;
        }

        .unknown-container--divider {
            @include m-global.size(100, 1px);
            margin-top: 20px;
            margin-bottom: 20px;
            opacity: 1;
            background-color: $color-grey-300;
            display: block;
            border:none;
        }


        .unknown-container--message {
            @include m-global.font(v-global.$medium, 400, 32px, 0.24px, var(--cg-grey-900));
            font-family: v-global.$primaryfont;
            margin-bottom: 40px !important;

            @media (width <= $tablet) {
                @include m-global.font(v-global.$regular, $height:24px, $spacing: 0.014px);
            }
        }

        .unknown-container--cto-link {
            max-width: 248px;
            box-shadow: none;

            @media (width <= $phone) {
                max-width: 100%;
            }
        }

        .unknown-container--header {
            flex-direction: row;
            align-items: center;
            gap: 12px;

            .unknown-container--header-leading-icon {
                --md-icon-size: 48px;
                color: var(--cg-grey-500);
             }

             .unknown-container--header-title {
                @include m-global.font(v-global.$huge, 700, 36px, 0px, $color: var(--cg-grey-900));
                margin: 0;
                font-family: v-global.$primaryfont;

                @media (width <= $tablet) {
                    white-space: nowrap;
                    font-size: v-global.$extralarge;
                }

                @media (width <= $phone) {
                    line-height: 28px;
                }
             }
        }
    }
    .message-content {
        @include m-global.font(v-global.$medium, 400, 32px, 0.24px, var(--cg-grey-900));
        font-family: v-global.$primaryfont;
        font-style: normal;
    }

    .btn-try {
        @include m-global.container-fluid(248px);
        @include m-global.font(v-global.$medium, 500, 20px, 0.04px, var(--cg-grey-100) !important);
        height: 52px;

        @media (width <= $tablet) {
            @include m-global.container-fluid(100%);
        }
    }
}
