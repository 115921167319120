@import 'styles/variables';

#auth {
    width: 100%;

    .row {
        margin-left: unset;
        margin-right: unset;
    }

    .logo-container {
        @media (max-width: 1280px) {
            position: absolute;
            height: 20px;
        }
    }

    .logo-col {
        background: linear-gradient(90.12deg, rgba(67, 0, 169, 1) 2.45%, rgba(0, 51, 140, 1) 101.9%);
        width: 250px;

        .desktop-logo {
           margin-top: 13px;
           margin-left: 32px;
           max-width: 148px;
           max-height: 43px;
        }

        @media (max-width: 1280px) {
                max-width: unset;
            }
        
        @media (min-width: 1281px) {
            flex: 0 0 auto;
            display: inline;
        }
        
        @media (max-width: 1280px) {
            display: none !important;
        }
    }

    .txt-col {
        padding: calc(50vh - 342px) 78.5px 34px;

        h1 {
            color: $purple-text;
        }

        @media (max-width: 1280px) {
            padding: 267px 158px 34px;

            h1 {
                color: white;
            }
        }

        @media (max-width: $phone) {
            padding: 185px 53px 34px;
        
            h1 {
                font-size: $morehuge;
            }
        }
    }

    .img-col {
        flex: 1 0 0%;
        display: flex !important;
        @include flex-center();
        background-color: $cx-blue;
        padding: 0;

        @media (max-width: 1280px) {
            display: none !important;
        }        
    }

    .form-img {
        width: 100vw;
        height: 100%;
        object-fit: cover;
    }

    .return {
        top: calc(50vh - 434px);
        left: 78.5px;
        color: $purple-text;
        font-weight: 500;
        cursor: pointer;

        @media (max-width: 1280px) {
            left: unset !important;
            top: 205px !important;
            color: white;
        }
        
        @media (max-width: $phone) {
            top: 123px !important;
        }

        .back-button {
            color: $purple-text;
            font-size: 22px;

            @media (max-width: 1280px) {
                color: white;
            }
        }
    }

    @media (max-width: 1280px) {
        background: linear-gradient(
            90.12deg,
            $cx-purple 2.45%,
            $cx-blue 101.9%
        );

        .auth-container {
            background-color: $transparent;
        }

        .auth-footer {
            opacity: 75%;
            font-size: $small;
        }
    }

    @media (max-width: ($phone)) {
        & .row.vh-100 {
            width: 100%;
            margin-right: 0px;
            margin-left: 0px;
        }
    }

    .logo-position {
        position: relative;
        z-index: 3;
        
        @media(max-width: 1280px) {
            margin: 20px 0 0 158px;
            left: 0;
            top: 0;
        }
        
        @media (max-width: $phone) {
            left: 0;
            right: 0;
            margin: 20px 0 0 53px;
            width: auto;
            height: auto;
        }
        
        @media (min-width: 1281px) {
            display: none !important;
        }
    }

    .chicksx-logo {
        width: 170px;
        height: 49px;
    
        @media (max-width: $phone) {
            width: 111px;
            height: 32px;
        }
    }

    .form-position {
        display: flex;
        align-items: center;
        max-width: 608px;
        min-height: 100vh;
        margin-top: auto;
        margin-bottom: auto;

        @media (max-width: 1280px) {
            max-width: 100%;
            display: unset;
            align-items: unset;
        }
                
        @media (min-width: 1280px) {
            display: unset;
        }
    }

    cx-auth-form {
        flex: 0 0 100%;

        @media (width <= $extralargescreen) {
            flex: unset;
        }
    }

    a {
        color: $purple-text;
    }
}
