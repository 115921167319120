@use 'styles/variables' as var;
@use '@chicksgroup/styles/mixins' as m;

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

:root {
    --large-button-skeleton: 72px;
    --cds-primary-font: #{var.$primaryfont};
    --cds-large-text: #{var.$large};
    --cds-medium-text: #{var.$medium};

    --cds-small-pad: #{var.$smallPad};
    --cds-regular-pad: #{var.$regularPad};
    --cds-medium-pad: #{var.$mediumPad};
    --cds-large-text: #{var.$extralarge};

    --cds-primary-background: #{var.$cx-blue};
    --cds-secondary-background: #{var.$white};
    --cds-text-color: #{var.$black};
    --cds-toast-title-gap: #{var.$regularPad};

    --cds-primary-color: #{var.$slateblue};
    --cds-success-color: #{var.$success-green};
    --cds-error-color: #{var.$notification-red};
    --cds-warning-color: #{var.$yellow};
    --cds-tertiary-color: #{var.$purple-text};

    --cds-global-shape: #{var.$thinborder};

    --cds-snackbar-info-color: #{var.$snackbar-info};
    --cds-snackbar-success-color: #{var.$snackbar-success};
    --cds-snackbar-warning-color: #{var.$snackbar-warning};
    --cds-snackbar-error-color: #{var.$snackbar-error};
    --cds-snackbar-text-color: #{var.$color-grey-200};
    --cds-snackbar-closing-icon-size: 12px;

    --cds-dynamic-banner-margin-title: #{var.$extrasmall};

    --md-sys-color-primary: #{var.$lilac-btn};

    --Color-Grey-1100: #{var.$color-grey-1100};
    --Color-Grey-1000: #{var.$color-grey-1000};
    --Color-Grey-900: #{var.$color-grey-900};
    --Color-Grey-800: #{var.$color-grey-800};
    --Color-Grey-700: #{var.$color-grey-700};
    --Color-Grey-600: #{var.$color-grey-600};
    --Color-Grey-450: #{var.$color-grey-450};
    --Color-Grey-400: #{var.$color-grey-400};
    --Color-Grey-500: #{var.$color-grey-500};
    --Color-Grey-300: #{var.$color-grey-300};
    --Color-Grey-200: #{var.$color-grey-200};
    --Color-Grey-100: #{var.$color-grey-100};

    --Primary-300: #{var.$primary-300};
    --Primary-200: #{var.$primary-200};

    --Secondary-600: #{var.$color-secondary-600};

    // Tooltip
    --cds-tooltip-background-color: #{var.$tooltip-primary};
    --cds-tooltip-border-color: #{var.$tooltip-primary};
    --cds-tooltip-text-size: #{var.$small};
    --cds-tooltip-font-weight: 300;
    --cds-tooltip-text-line-height: #{var.$medium};

    // Button

    cds-toast {
        position: relative;
        top: #{var.$extrabigpad};
    }

    //Social Circles

    cds-social-circles {
        --cds-social-circles-background-color: #310FA2;
        --cds-social-circles-background-color-hover: #180088;
        --cds-social-circles-background-color-pressed: #00006F;
    }

    // Dynamic banner
    --cds-dynamic-banner-min-width-button: 332px;
    --cds-dynamic-banner-height-button: 52px;

    --cds-dynamic-banner-image-container-margin: 0;
    --cds-dynamic-banner-margin-description: 0;
    --cds-dynamic-banner-description-color: #{var.$lightgray};

    cds-dynamic-banner {
        --md-filled-button-label-text-size: 16px;
    }

    @media (width < var.$desktop) {
        --cds-dynamic-banner-display: flex;
        --cds-dynamic-banner-direction: column-reverse;
        --cds-dynamic-banner-max-width: 542px;
        --cds-dynamic-banner-min-height: 100%;
        --cds-dynamic-banner-max-height: 283px;

        --cds-dynamic-banner-width-content: 272px;
        --cds-dynamic-banner-height-content: 222px;
        --cds-dynamic-banner-margin-content: 0;

        --cds-dynamic-banner-min-height-text-content: 100px;

        --cds-dynamic-banner-container-padding: #{var.$mediumPad};

        --cds-dynamic-banner-title-size: #{var.$largeplus};
        --cds-dynamic-banner-title-line-height: 29px;

        --cds-dynamic-banner-description-size: #{var.$regular};
        --cds-dynamic-banner-description-line-height: #{var.$extralargeplus};

        --cds-dynamic-banner-min-width-button: 100%;
        --cds-dynamic-banner-max-width-button: 272px;

        --cds-dynamic-banner-image-container-margin: 0 0 0 23px;
        --cds-dynamic-banner-image-container-width: 100%;
        --cds-dynamic-banner-image-container-max-width: 184px;
        --cds-dynamic-banner-image-container-height: 223px;
        --cds-dynamic-banner-image-container-justify: center;

        --cds-dynamic-banner-image-width: 184px;
        --cds-dynamic-banner-image-height: 179px;
    }

    @media (width < var.$tablet) {
        --cds-dynamic-banner-max-width: 336px;
        --cds-dynamic-banner-max-height: 432px;

        --cds-dynamic-banner-container-padding: #{var.$regularPad};
        --cds-dynamic-banner-container-direction: column-reverse;

        --cds-dynamic-banner-width-content: 100%;
        --cds-dynamic-banner-min-height-content: 162px;
        --cds-dynamic-banner-height-content: auto;
        --cds-dynamic-banner-max-height-content: 183px;

        --cds-dynamic-banner-min-height-text-content: 63px;

        --cds-dynamic-banner-title-size: #{var.$regular};
        --cds-dynamic-banner-title-line-height: 17px;

        --cds-dynamic-banner-description-size: #{var.$small};
        --cds-dynamic-banner-margin-description: 0 0 #{var.$regularPad} 0;
        --cds-dynamic-banner-description-line-height: 21px;

        --cds-dynamic-banner-max-width-button: 294px;

        --cds-dynamic-banner-image-container-max-width: 180px;
        --cds-dynamic-banner-image-container-height: auto;
        --cds-dynamic-banner-image-container-margin: 0 0 25px 0;
    }
}
