@import 'styles/variables';

#author {
    margin-top: 162px;

    @media (width <= $tablet) {
        margin-top: 112px;
    }

    .author-avatar {
        @include square(160px);
        border-radius: 50%;
        margin-bottom: 10px;

        @media (width <= $tablet) {
            @include square(80px);
        }
    }

    .social-icon {
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .author-name {
        font-size: $extralarge;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .author-about {
        max-width: 600px;

        @media (width <= $tablet) {
            max-width: 542px;
        }
    }

    .author-description {
        max-width: 922px;

        @media (width <= $tablet) {
            max-width: 542px;
        }
    }

    .author-about,
    .author-description {
        font-size: $medium;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 40px;

        @media (width <= $tablet) {
            font-size: $regular;
        }
    }

    .articles-link {
        font-weight: 500;
        font-size: $medium;
        color: $purple-text;
        margin-bottom: 80px;

        @media (width <= $tablet) {
            margin-bottom: 40px;
        }

        @media (width <= $phone) {
            margin-bottom: 40px;
        }
    }

    .divider {
        max-width: 922px;

        @media (width <= $tablet) {
            max-width: 542px;
        }

        @media (width <= $phone) {
            max-width: 334px;
        }

        hr {
            border-top: 2px solid $softblack;
            opacity: 1 !important;
            margin-bottom: 80px;
            margin-top: 0;

            @media (width <= $tablet) {
                margin-bottom: 40px;
            }
        }
    }

    .pagination-author {
        margin-top: 80px;

        @media (width <= $tablet) {
            margin-top: 40px;
        }

        @media (width <= $phone) {
            margin-top: 20px;
        }
    }

    .breadcrumbs {
        margin-bottom: 26px;

        @media (width <= $desktop) {
            margin-bottom: 40px;
        }
    }
}
