@import 'styles/variables';
@import 'styles/eeta-page-style';

#accessibility {
    
    .text-content:not(:last-child)::after {
        content: "";
        display: block;
        width: 100%;
        margin: 0px auto;
        height: 0px !important;
        background-color: rgb(231, 231, 231);
    }

    .legal-page-skeleton {
        border-top: unset !important;
    }

    &.skeleton-policy::after {
        background-color: unset !important;
    }

    .legal-page-skeleton {
        border-top: unset !important;
    }

    .skeleton-loading {
        display: flex;
        background-color: $ghost-white !important;

        @media (max-width: $desktop) {
            margin-top: 25px;
        }

        &.tos-title {
            width: 230px;
            margin-left: 0;
            height: 35px;
            margin-top: 0px;
            margin-bottom: 0px !important;

            @media (max-width: 376px) {
                width: 160px;
            }
        }

        &.last-update {
            height: 25px;
            width: 280px;
            margin-top: $regularPad;
            margin-left: auto;
        }

        &.tech-title {
            width: 150px;
            height: $regularPad;
        }

        &.tech-content {

            @media (max-width: $phone) {
                height: 130px;
            }
        }
    }
}