@use '@material/select/_index' as select;
@use 'styles/mixins' as m;
@use '@material/textfield';
@use 'styles/variables' as v;

#blogpost {
    margin-top: 65px;

    @media (width < v.$desktop) {
        margin-top: v.$largePad;
    }

    @media (width < v.$tablet) {
        margin-top: 52px;
    }

    .title-page {
        margin-top: v.$hugePad;

        @media (width < v.$desktop) {
            margin-top: v.$largePad;
        }

        @media (width < v.$tablet) {
            margin-top: v.$bigPad;
        }

        h1 {
            @include m.truncate-text(2);
            color: v.$softblack;
            font-family: v.$primaryfont;
            font-weight: 700;
            margin: 0;

            @media (width < v.$desktop) {
                font-size: v.$extralarge;
            }
        }
    }

    .container,
    .blogpost-container {
        max-width: 922px;
        padding: 0;

        @media (max-width: v.$desktop) {
            max-width: 542px;
        }

        @media (width < v.$tablet) {
            padding-bottom: 40px;
        }

        @media (max-width: v.$phone) {
            padding-bottom: 0;
            max-width: calc(100% - 40px);
        }
    }

    .content-padding {
        margin-bottom: v.$hugePad;

        @media (width < v.$desktop) {
            margin-bottom: v.$largePad;
        }

        @media (width < v.$tablet) {
            margin-bottom: v.$bigPad;
        }

        span {
            font-size: v.$extrahuge;
            color: v.$softblack;
        }

        img {
            max-width: 100%;
        }
    }

    .blogpost-container {
        .blog-image {
            margin-bottom: v.$bigPad;
            width: 100%;
            object-fit: fill;
            max-height: 500px;

            @media (max-width: v.$tablet) {
                position: relative;
                max-width: 100%;
            }
        }

        .header-title {
            font-size: v.$extrahugetitle;
            color: v.$softblack;
            margin-bottom: 20px;
            width: 608px;
            overflow-wrap: anywhere;
            word-break: normal;

            @media (width < v.$desktop) {
                font-size: v.$extralarge;
                margin-bottom: v.$tiny;
            }
        }

        .published-at {
            margin-bottom: 40px;

            & span,
            a {
                color: v.$gray-default;
                font-size: v.$medium;

                @media (width < v.$tablet) {
                    margin-bottom: v.$tiny;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            @media (width < v.$desktop) {
                flex-direction: column;
                justify-content: center;

                .published {
                    flex-wrap: wrap;
                    margin: auto;
                }

                .divisors {
                    display: none;
                }
            }

            @media (width <= v.$phone) {
                .published {
                    flex-direction: column;
                }
            }

        }

        .by-author:hover {
            color: v.$cx-purple !important;
            text-decoration: underline !important;
        }

        .blog-content {
            overflow-wrap: anywhere;
            word-break: normal;

            h1 span,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            ol {
                color: v.$darkergray;
            }

            h1 span {
                font-size: v.$extrahugetitle;
                font-weight: 700;

                @media (max-width: v.$tablet) {
                    font-size: v.$huge;
                    font-weight: 400;
                }
            }

            h2 {
                font-size: v.$extralarge;
                font-weight: 700;

                @media (max-width: v.$tablet) {
                    font-size: v.$largeplus;
                }
            }

            h3 {
                font-size: v.$largeplus;
                font-weight: 500;

                @media (max-width: v.$tablet) {
                    font-size: v.$large;
                }
            }

            h4 {
                font-size: v.$large;
                font-weight: 500;

                @media (max-width: v.$tablet) {
                    font-size: v.$medium;
                }
            }

            h5 {
                font-size: v.$medium;
                font-weight: 500;

                @media (max-width: v.$tablet) {
                    font-size: v.$regular;
                }
            }

            p {
                font-size: v.$medium;
                font-weight: 400;
                line-height: 32px;

                @media (max-width: v.$tablet) {
                    font-size: v.$regular;
                    line-height: 28px;
                }
            }

            p:has(img) {
                img {
                    max-width: 100%;
                }

                @media (max-width: v.$tablet) {
                    overflow: hidden;
                    width: 542px;

                    img {
                        position: relative;
                        transform-origin: center;
                        max-width: 100%;
                    }
                }

                @media (max-width: v.$phone) {
                    width: 100%;
                }
            }

            p:last-child {
                margin-bottom: v.$bigPad;
            }

            ul,
            ol {
                font-weight: 400;
                font-size: v.$medium;

                @media (max-width: v.$phone) {
                    font-size: v.$regular;
                }
            }

            a {
                color: v.$purple-text;

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }

        hr {
            border-top: 2px solid v.$cx-divider;
            opacity: 1 !important;
            margin-bottom: v.$medium;
        }

        hr:last-child {
            margin-bottom: v.$bigPad;
        }

        .author-profile {
            margin-top: v.$extralarge;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-bottom: v.$smallPad;
        }

        .author-name a {
            color: v.$gray-default;
            margin-bottom: v.$smallPad;
            font-size: v.$extralarge;
            font-weight: 700;
        }

        .author-description {
            @include m.flex(center, center);
            @include m.font(v.$medium, 400, $color: v.$gray-default);
            text-align: center;
            margin-bottom: v.$bigPad;
        }

        .articles {
            margin-bottom: v.$bigPad;

            span {
                @include m.font(v.$extrahugetitle, 700, $color: v.$gray-default);
                font-family: v.$primaryfont;
            }
        }
    }

    .form-control {
        &:focus {
            box-shadow: none;
        }
    }

    .cx-dropdown {
        width: 299px;
        margin-bottom: v.$hugePad;
        background: white !important;
        border-radius: 12px;

        & span {
            color: black;
        }

        @media (max-width: v.$phone) {
            margin-left: 160px;
            margin-top: 2px;
            width: 169px;
        }
    }

    .img-container {
        width: 100%;
        max-width: 100%;

        @media (max-width: v.$tablet) {
            overflow: hidden;
            width: 542px;
        }

        @media (max-width: v.$phone) {
            margin-bottom: 0;
        }
    }

    .dropdown-container {
        margin-left: 5px;

        @media (max-width: v.$tablet) {
            width: 542px;
            margin-left: 115px;
        }

        @media (max-width: v.$phone) {
            margin-left: 0;
            width: 100%;
        }
    }

    .hidden-elements {
        @media (max-width: v.$desktop) {
            display: none;
        }
    }

    .flag-icon {
        width: 24px;
        height: 13px;
    }

    .mdc-select .mdc-menu-surface {
        border-radius: 12px;
    }

    .breadcrumbs {
        margin-block: v.$bigPad;
        width: 100%;

        ul {
            list-style-type: none;
            padding-left: 0;
        }

        @media (width < v.$desktop) {
            margin-block: v.$regularPad;
        }
    }

    .divisors {
        margin-inline: v.$medium;
        @media (max-width: v.$phone) {
            margin: 0 5px;
        }
    }

    .content-padding .no-image span {
        font-size: 15rem;
    }

    #cx-posts-cards {
        .card-deck {    
            @media (width < v.$desktop) {
                .card:last-child {
                    display: none;
                }
            }
    
            @media (width < v.$tablet) {    
                .card:not(:first-child) {
                    display: none;
                }
            }
        }
    }
}
