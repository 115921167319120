#cookies,
#editorial-policy,
#modern-slavery-statement,
#code-of-ethics,
#complaints-policy,
#accessibility,
#disclaimer,
#bug-bounty {

    font-family: $primaryfont;
    margin-bottom: $giantmedium;

    @media (max-width: $tablet) {
        margin-bottom: $extrahugetitle;
    }

    @media (max-width: $phone) {
        margin-bottom: $largeplus;
    }

    .header {
        margin-top: 65px;
        padding: 160px 0 $bigPad;
        border-block: 1px solid $color-grey-400;

        @media (max-width: $tablet) {
            padding: $largePad 0 $bigPad;
            border-bottom: none;
        }

        @media (max-width: $phone) {
            padding-top: $bigPad;
            margin-top: $largePad;
        }
    }

    hr {
        margin: $bigPad 0;
        border-top: 1px solid $purple-text;
    }

    .header-title {
        max-width: 766px;
        font-size: $moreplushuge;
        line-height: $extrahuge;
        letter-spacing: 1.28px;
        font-weight: 700;
        margin: 0 auto;

        p {
            margin: 0;
        }

        @media (max-width: $tablet) {
            font-size: $moreplushuge;
            line-height: $extrahuge;
            width: 95%;
            margin: 0 auto;
        }

        @media (max-width: $phone) {
            font-size: $extralarge;
            line-height: $huge;
            width: 100%;
            max-width: 336px;
        }

        @media ($extrascreen <= width) {
            margin-left: 23.07%;
        }
    }

    .tos-body {
        width: 95%;
        max-width: 766px;
        margin: 0 auto;

        p {
            line-height: 19px;
            letter-spacing: 0.035px;
            color: $main-title-without-bg;
        }

        .margened-paragraph {
            p:has(strong) {
                margin-left: $extrahugetitle;
            }

            p:nth-of-type(21):has(strong),
            p:nth-of-type(26):has(strong) {
                margin-left: 0;
            }
        }

        p:not(.text-content):has(strong) strong {
            line-height: normal;
            font-size: $medium !important;
            letter-spacing: 0.5px;
            font-weight: bold !important;
        }


        @media ($extrascreen <= width) {
            margin-left: 23.07%;
        }

        @media (max-width: $desktop) {
            max-width: 728px;
        }

        @media (max-width: $tablet) {
            border-top: 1px solid $color-grey-400;
        }

        @media (max-width: $phone) {
            width: 100%;
            max-width: 335px;
            border-top: 1px solid $color-grey-400;
        }

        .text-title p {
            line-height: normal;
            font-size: $extralarge;
            letter-spacing: 0.5px;

            @media ($desktop <= width) {
                font-size: $extralarge;
            }

            @media (max-width: $phone) {
                font-size: $largeplus;
            }
        }

        p,
        .text-content p,
        .text-content p:has(strong) strong {
            line-height: $moreplushuge;
            letter-spacing: 0.512px;
            color: $color-grey-1100;
            font-size: $medium;
            font-weight: 400;
        }

    }

    .last-update p {
        color: $purple-text;
        font-size: $medium;
        margin: 40px 0 20px 0;
        font-weight: 600;
        line-height: $extralarge;
    }

    .divider {
        width: 100%;
        height: 1px;
        background: $primaryborder;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .text-title p {
        font-size: $extralarge;
        color: $purple-text;
        line-height: 28px;
        letter-spacing: 0.77px;
        margin-bottom: 20px;
        font-weight: 600;
    }

    .text-content p,
    ul,
    ol {
        font-size: $medium;
        margin-bottom: 20px;

        a {
            color: $purple-text;

            &:hover {
                text-decoration: underline !important;
            }
        }
    }

    .text-content:not(:last-child)::after {
        content: '';
        display: block;
        width: 100%;
        margin: 40px auto;
        height: .5px;
        background-color: $color-grey-300;
    }

}
