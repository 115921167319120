@use '@material/select/_index' as select;
@use '@material/textfield';
@use 'styles/mixins' as m;
@use 'styles/variables' as v;

#blogs {

    .skeleton-loading {
        background-color: v.$ghost-white !important;
        border-top: none !important;

        &.blogs-title {
            height: 48px;
            width: 109px;

            @media (max-width: 768px) {
                height: 28px;
            }
        }

        &.navigation {
            margin-top: 20px;
            width: 100px;
            height: 24px;
            position: relative;
            left: -410px;

            @media (max-width: 1000px) {
                left: 20px;
                margin-bottom: 20px;
                margin-left: 0px;
            }
        }

        &.back {
            display: none;
        }

        &.tags {
            height: 48px;
            width: 322px !important;
        }

        &.pagination-skeleton {
            width: 400px;
            height: 48px;
            margin: 0 auto;
            @include m.flex-center();
        }

        &.container-skeleton {
            display: flex;
            gap: 16px;

            &.card-skeleton {
                width: 294px;
                height: 290px;
                background-color: #F6F6FD;
                border-radius: 4px;
            }
        }

        &.post-skeleton {
            width: 922px;
            height: 300px;
            margin-top: 16px;
        }

        &.breadcrumb-skeleton {
            height: 1250px;
            width: auto;
        }

        &.text-skeleton {
            width: 922px;
            height: 1px;
        }

        &.filter-tags {
            width: 400px;
            margin-top: 16px;

        }

        &.blogs {
            width: 922px;
            height: 50px;
        }
    }

    margin-top: 65px;

    @media (max-width: v.$tablet) {
        margin-top: 60px;
    }

    @media (max-width: v.$phone) {
        margin-top: 52px;
    }

    .container {
        max-width: 922px;
        padding: 0;

        @media (max-width: v.$desktop) {
            max-width: 542px;
        }

        @media (width < v.$tablet) {
            max-width: calc(100% - v.$extrahugetitle);
        }
    }

    .main-container {
        @media (width < v.$desktop) {
            max-width: calc(100% - v.$bigPad);
        }
    }

    .title-page {
        margin-top: v.$hugePad;

        @media (width < v.$desktop) {
            margin-top: v.$largePad;
        }

        @media (width < v.$tablet) {
            margin-top: v.$extrahugetitle;
        }

        h1 {
            color: v.$softblack;
            font-family: v.$primaryfont;
            font-weight: 700;
            margin: 0;

            @media (width < v.$desktop) {
                font-size: 24px;
            }
        }
    }

    .sticky-post-container {
        margin-top: 40px;

        @media (width < v.$desktop) {
            margin-top: v.$regularPad;
        }

        @media (max-width: v.$phone) {
            margin-top: 20px;
        }
    }

    .blogs-input-search {
        margin-top: v.$extrahugetitle;
        padding-left: 0;
        padding-right: 0;

        @media (width < v.$desktop) {
            margin-top: 0;
        }
    }

    .text-input {
        margin-right: 20px;

        @media (max-width: v.$desktop) {
            height: 39px !important;
        }

        @media (max-width: v.$phone) {
            margin-bottom: 8px;
            margin-right: 0;
        }
    }

    .cx-dropdown {
        @include select.outline-color(('default': v.$transparent, 'hover': v.$purplehover, 'focus': v.$purple-text));
        @include select.outline-shape-radius(12px);
        border-radius: 12px;
        width: 169px;

        @media (max-width: v.$phone) {
            margin-bottom: 2px;
        }

        @media (max-width: v.$desktop) {
            height: 39px;
            @include select.outlined-height(39px);
        }
    }

    mdc-select span:first-child {
        color: v.$black;
        font-size: v.$regular;
        width: 52px;
        padding-top: 4px;
    }

    mdc-select {
        background: v.$white;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
    }

    .blogs-loader {
        @include m.flex-center(column);
        @include m.full-size();

        .loader-text {
            font-size: v.$extralarge;
            margin-top: 20px;
        }
    }

    .filter-tags {
        max-height: 25px;
        margin-top: 40px;
        margin-bottom: -76px;

        @media (max-width: v.$desktop) {
            max-height: unset;
            height: 69px;
            margin-top: 30px;
            margin-bottom: 0;
            overflow: auto hidden;
        }

        @media (max-width: v.$phone) {
            height: 75px;
            margin-top: 23px;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .tags-list {
            list-style: none;
            margin: 0;

            @media (max-width: v.$desktop) {
                padding-top: 10px;
                padding-bottom: 22px;
            }

            @media (max-width: v.$phone) {
                padding: 17px 0;
            }
        }

        .tag-option {
            color: v.$midgray;
            float: left;
            line-height: 19px;
            letter-spacing: 0.448px;
            white-space: nowrap;

            &:hover,
            &.active {
                color: v.$purple-text;
            }

            @media (max-width: v.$desktop) {
                &:not(:last-of-type) {
                    margin-right: 52px;
                }
            }

            @media (max-width: v.$phone) {
                &:not(:last-of-type) {
                    margin-right: 20px;
                }
            }
        }
    }

    .line {
        margin-top: 40px;
        margin-bottom: 40px;

        @media (max-width: v.$desktop) {
            margin-bottom: 20px;
        }
    }

    hr {
        border-top: 2px solid v.$cx-divider;
        opacity: 1 !important;
    }

    .post-cards {
        width: 980px;
        margin-top: 115px;

        @media (max-width: v.$desktop) {
            margin-top: 0;
        }

        @media (max-width: v.$phone) {
            width: 370px;
            padding-right: 0;
        }
    }

    .no-results {
        font-weight: 500;
        font-size: v.$extrahugetitle;
        margin-top: 60px;
    }

    .instead {
        font-size: v.$largeplus;
        font-weight: 500;
        margin-bottom: 30px;
    }

    #cx-sticky-post {
        @media (max-width: v.$tablet) {
            max-width: 100%;
            width: 100%;
        }

        .content-box {

            & a {
                color: v.$gray-blue;
            }

            @media (max-width: v.$tablet) {
                width: 100%;
            }
        }

        .content p {
            color: v.$gray-blue;
        }

        .content a p {
            color: v.$purple-text;
        }

        .content,
        .blog-title,
        .blog-image {
            @media (max-width: v.$phone) {
                margin-left: 0;
            }
        }
    }

    .pagination-container {
        margin-top: 40px;
        margin-bottom: v.$hugePad;

        @media (width < v.$desktop) {
            margin-bottom: v.$largePad;
        }

        @media (width < v.$tablet) {
            margin-block: v.$extrahugetitle;
        }
    }

    .breadcrumbs {
        padding-top: v.$regularPad;

        @media (width < v.$desktop) {
            padding-top: v.$extrahugetitle;
        }

        @media (max-width: v.$phone) {
            padding-top: 20px !important;
        }
    }

    ul {
        list-style-type: none;
        padding-left: 0;
    }

    li {
        float: left;

        a,
        span {
            color: v.$softblack;
        }

        a {
            @media (max-width: v.$phone) {
                font-size: v.$medium;
            }
        }
    }

    .crumb-effect:hover {
        font-weight: 700;
        text-decoration: underline !important;
    }

    .return {
        margin: 20px 0 4px -6px;

        @media (width < v.$desktop) {
            margin-block: v.$extrahugetitle v.$regularPad;
        }
    }
}